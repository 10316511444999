import React from 'react';
import 'leaflet/dist/leaflet.css';

import Leaf from '../components/Leaf';

export default function Philly () {
  if (typeof window !== `undefined`) {
    return (
      <div>
        <Leaf />
      </div>
    );
  }
  return null;
}
