import {useEffect} from 'react';
import {useLeaflet} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-choropleth';

const style = {
  fillColor: '#ababab',
  weight: 2,
  opacity: 1,
  color: 'white',
  dashArray: '3',
  fillOpacity: 0.7,
};

export default function Choropleth() {
  const {map} = useLeaflet();

  useEffect(() => {
    fetch('/static/philadelphia-homicides2020.geojson')
      .then((response) => response.json())
      .then((geojson) => {
        L.choropleth(geojson, {
          valueProperty: 'rate', // which property in the features to use
          scale: [
            '#440154',
            '#482777',
            '#3F4A8A',
            '#31678E',
            '#26838F',
            '#1F9D8A',
            '#6CCE5A',
            '#B6DE2B',
            '#FEE825',
          ], // chroma.js scale - include as many as you like
          steps: 9, // number of breaks or steps in range
          mode: 'q', // q for quantile, e for equidistant, k for k-means
          style,
          onEachFeature: function(features, layer) {
            layer.bindPopup(
              'Homicide Rate: ' +
                Math.round(features.properties.rate * 10) / 10 +
                '<br>' +
                'Homicides: ' +
                features.properties.HOMNUM +
                '<br>' +
                'Percent Black: ' +
                Math.round(features.properties.per_black * 100) +
                ' %<br>' +
                'Percent White: ' +
                Math.round(features.properties.per_white * 100) +
                ' %<br>' +
                'Percent Hispanic: ' +
                Math.round(features.properties.per_hispanic * 100) +
                ' %<br>' +
                'Percent Asian: ' +
                Math.round(features.properties.per_asian * 100) +
                ' %<br>' +
                'Percent Other: ' +
                Math.round(features.properties.per_other * 100) +
                ' %<br>' +
                'Unmarries Births: ' +
                Math.round(features.properties.unmarried * 100) +
                ' %<br>' +
                'Median Age: ' +
                features.properties.median_age +
                '<br>' +
                'Median Household Income: ' +
                features.properties.household_income +
                '<br>'
            );
          },
        }).addTo(map);
      });
  }, []);

  return null;
}
