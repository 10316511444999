import React, {Component} from 'react';
import { Map, TileLayer, ZoomControl } from "react-leaflet";
import Choropleth from '../components/Choropleth'
import 'leaflet/dist/leaflet.css';

class Leaf extends Component {
  render() {
    const position = [39.9897471840457, -75.13893127441406];

    return (
      <div>
          <Map center={position} zoom={11} style={{height: '100vh'}}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Choropleth/>
          </Map>
      </div>
    );
  }
}

export default Leaf;
